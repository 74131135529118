@tailwind base;
@tailwind components;
@tailwind utilities;

.uppy-Root {
  font-family: 'Greycliff CF' !important;
  font-weight: 500 !important;
}

.uppy-Root * {
  font-family: 'Greycliff CF' !important;
  font-weight: 500 !important;
}

.loading-bars {
  width: 45px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#efe9d6 0 0);
  background: var(--c), var(--c), var(--c);
  animation: b8-1 1s infinite, b8-2 1s infinite;
}

.bars-8 {
  width: 45px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#000 0 0);
  background: var(--c), var(--c), var(--c);
  animation: b8-1 1s infinite, b8-2 1s infinite;
}

.dark .bars-8 {
  --c: no-repeat linear-gradient(#efe9d6 0 0);
}

.bars-5 {
  width: 45px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#000 0 0);
  background: var(--c), var(--c), var(--c);
  animation: b5-1 1s infinite, b5-2 1s infinite;
}

.dark .bars-5 {
  --c: no-repeat linear-gradient(#efe9d6 0 0);
}

@keyframes b5-1 {
  0%,
  100% {
    background-size: 20% 100%;
  }
  33%,
  66% {
    background-size: 20% 40%;
  }
}

@keyframes b5-2 {
  0%,
  33% {
    background-position: 0 0, 50% 100%, 100% 100%;
  }
  66%,
  100% {
    background-position: 100% 0, 0 100%, 50% 100%;
  }
}

@keyframes b8-1 {
  0%,
  100% {
    background-size: 20% 100%;
  }
  33%,
  66% {
    background-size: 20% 20%;
  }
}

@keyframes b8-2 {
  0%,
  33% {
    background-position: 0 0, 50% 50%, 100% 100%;
  }
  66%,
  100% {
    background-position: 100% 0, 50% 50%, 0 100%;
  }
}

.gradient {
  background-color: rgba(133, 77, 14, 25%);
  background-image: radial-gradient(at 59% 9%, rgba(245, 245, 244, 25%) 0, transparent 33%),
    radial-gradient(at 97% 63%, rgba(248, 250, 252, 25%) 0, transparent 80%),
    radial-gradient(at 59% 61%, rgba(217, 119, 6, 25%) 0, transparent 24%),
    radial-gradient(at 88% 42%, rgba(154, 52, 18, 25%) 0, transparent 28%),
    radial-gradient(at 23% 70%, rgba(250, 250, 250, 25%) 0, transparent 95%),
    radial-gradient(at 29% 52%, rgba(168, 162, 158, 25%) 0, transparent 41%);
}

.dark .gradient {
  background-color: rgba(133, 77, 14, 5%);
  background-image: radial-gradient(at 59% 9%, rgba(245, 245, 244, 5%) 0, transparent 33%),
    radial-gradient(at 97% 63%, rgba(248, 250, 252, 5%) 0, transparent 80%),
    radial-gradient(at 59% 61%, rgba(217, 119, 6, 5%) 0, transparent 24%),
    radial-gradient(at 88% 42%, rgba(154, 52, 18, 5%) 0, transparent 28%),
    radial-gradient(at 23% 70%, rgba(250, 250, 250, 5%) 0, transparent 95%),
    radial-gradient(at 29% 52%, rgba(168, 162, 158, 5%) 0, transparent 41%);
}

@layer base {
  abbr {
    @apply no-underline;
  }

  html {
    @apply h-full;
  }

  body {
    @apply h-full;
    @apply text-zinc-900;
    @apply dark:text-white;
    @apply transition;
    @apply font-sans;
    @apply font-normal;
    @apply selection:bg-black/50;
    @apply dark:selection:bg-snow/50;
  }

  select {
    appearance: none;
  }

  @font-face {
    font-family: 'Greycliff CF';
    font-weight: 400;
    font-display: swap;
    src: url('../../public/fonts/greycliff/greycliff-cf-regular.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Greycliff CF';
    font-weight: 500;
    font-display: swap;
    src: url('../../public/fonts/greycliff/greycliff-cf-medium.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Greycliff CF';
    font-weight: 600;
    font-display: swap;
    font-style: normal;
    src: url('../../public/fonts/greycliff/greycliff-cf-demi-bold.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Greycliff CF';
    font-weight: 700;
    font-display: swap;
    font-style: normal;
    src: url('../../public/fonts/greycliff/greycliff-cf-bold.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Greycliff CF';
    font-weight: 800;
    font-display: swap;
    font-style: normal;
    src: url('../../public/fonts/greycliff/greycliff-cf-extra-bold.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Greycliff CF';
    font-weight: 900;
    font-display: swap;
    font-style: normal;
    src: url('../../public/fonts/greycliff/greycliff-cf-heavy.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Gooper';
    font-weight: 300;
    font-display: swap;
    font-style: normal;
    src: url('../../public/fonts/gooper/Gooper7-Light.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Gooper';
    font-weight: 400;
    font-display: swap;
    font-style: normal;
    src: url('../../public/fonts/gooper/Gooper7-Regular.woff2') format('woff2');
  }
}

@keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.react-loading-skeleton {
  --base-color: #e4e4e7;
  --highlight-color: #d4d4d8;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Enable animation */

  background-color: var(--base-color);

  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;
  display: inline-block;
  position: relative;
  overflow: hidden;
  z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
}

.dark .react-loading-skeleton {
  --base-color: #27272a;
  --highlight-color: #3f3f46;
}

.react-loading-skeleton::after {
  content: ' ';
  display: var(--pseudo-element-display);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  opacity: 0.8;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, var(--base-color), var(--highlight-color), var(--base-color));
  transform: translateX(-100%);

  animation-name: react-loading-skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.hero-popup .mapboxgl-popup-content {
  @apply p-0;
  @apply bg-transparent;
  @apply bg-brand-500;
  @apply rounded-lg;
  @apply sm:rounded-br-none;
  @apply outline-none;
  @apply ring-0;

  /* @apply shadow-subtle; */
}

.hero-popup .mapboxgl-popup-tip {
  @apply border-t-brand-500;
  /* @apply border-t-4; */
}

/* @layer { */
.nice-dates-navigation {
  @apply text-center;
  @apply items-center;
  @apply flex;
  @apply justify-between;
  @apply relative;
  @apply text-center;
  @apply capitalize;
  @apply text-brand-800;
  @apply font-medium;
}

.nice-dates-navigation_current {
  @apply flex-grow;
  @apply text-base;
  @apply font-semibold;
  @apply text-zinc-600;
}

.dark .nice-dates-navigation_current {
  @apply text-zinc-200;
}

.nice-dates-navigation_previous,
.nice-dates-navigation_next {
  @apply cursor-pointer;
  @apply h-0;
  @apply relative;
  padding-bottom: calc(100% / 7);
  width: calc(100% / 7);
}

.nice-dates-navigation_previous:before,
.nice-dates-navigation_next:before {
  @apply border-solid border-t-2 border-r-2 box-border block h-3 -mt-2 -ml-2 absolute w-3;
  @apply border-zinc-400;
  @apply content-[""];
  @apply left-1/2;
  @apply top-1/2;
  @apply rotate-45;
  @apply transition-colors;
}
.nice-dates-navigation_previous:hover:before,
.nice-dates-navigation_next:hover:before {
  @apply border-zinc-600;
}
.nice-dates-navigation_previous.-disabled,
.nice-dates-navigation_next.-disabled {
  @apply cursor-default pointer-events-none;
}
.nice-dates-navigation_previous.-disabled:before,
.nice-dates-navigation_next.-disabled:before {
  @apply border-zinc-300;
}
.nice-dates-navigation_previous:before {
  @apply rotate-[-135deg];
}

.nice-dates-week-header {
  @apply shadow-sm;
  @apply flex;
  @apply py-2;
}
.nice-dates-week-header_day {
  @apply text-zinc-400;
  @apply text-sm;
  @apply leading-tight;
  @apply text-center;
  @apply capitalize;
  width: calc(100% / 7);
}
.nice-dates-grid {
  @apply overflow-hidden;
  @apply relative;
  @apply box-content;
  @apply select-none;
  @apply rounded-lg;
  @apply border;
  @apply border-zinc-200;
  @apply dark:border-zinc-700;
}
.nice-dates-grid_container {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  @apply grid;
  @apply grid-cols-7;
  @apply gap-px;
  @apply left-0;
  @apply absolute;
  @apply right-0;
  @apply will-change-transform;
  @apply isolate;
  @apply rounded-lg;
  @apply bg-zinc-200;
  @apply dark:bg-zinc-700;
  /* @apply shadow; */

  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: none;
}
.nice-dates-grid_container.-moving .nice-dates-day_date {
  transition: 300ms color;
}
.nice-dates-grid_container.-origin-bottom {
  @apply top-auto;
  @apply bottom-0;
}
.nice-dates-grid_container.-origin-top {
  @apply top-0;
  @apply bottom-auto;
}
.nice-dates-grid_container.-transition {
  transition-property: transform;
}
.nice-dates-day {
  @apply border-t-transparent;
  @apply box-border;
  @apply text-zinc-600;
  @apply bg-white;
  @apply dark:bg-neutral-900;
  @apply cursor-pointer;
  @apply text-sm;
  @apply relative;
  @apply text-center;
  @apply items-center;
  @apply flex;
  @apply justify-center;
  @apply flex-col;
  @apply leading-none;
  @apply font-semibold;
}

.dark .nice-dates-day {
  @apply text-zinc-300;
}

.nice-dates-day_date {
  transition: 150ms color;
  will-change: color;
  z-index: 3;
}
.nice-dates-day_month {
  @apply hidden;
}
.nice-dates-day:before,
.nice-dates-day:after {
  @apply rounded-full;
  @apply mx-auto;
  @apply h-8;
  @apply w-8;
  @apply box-border;
  @apply content-[""];
  @apply block;
  @apply opacity-0;
  @apply absolute;
}
.nice-dates-day:before {
  @apply bg-zinc-900;
  @apply dark:bg-white;

  z-index: 1;
}

.nice-dates-day:after {
  @apply border-2;
  @apply border-zinc-300;
  transform: scale(0.95);
  transition-duration: 150ms;
  transition-property: transform, opacity;
  z-index: 2;
}

.dark .nice-dates-day:after {
  @apply border-zinc-600;
}

.nice-dates-day:not(.-disabled):hover:after {
  @apply opacity-100;
  @apply scale-100;
}
@media (hover: none) {
  .nice-dates-day:after {
    content: none;
  }
  .nice-dates-day.-selected * {
    @apply text-zinc-50;
    @apply dark:text-zinc-900;
  }
}
.nice-dates-day.-selected:before {
  @apply opacity-100;
}
.nice-dates-day.-selected:hover:after {
  @apply bg-zinc-50;
}
.nice-dates-day.-selected:not(:hover) *,
.nice-dates-day.-selected.-disabled * {
  @apply text-zinc-50;
  @apply dark:text-zinc-900;
}
.nice-dates-day.-selected.-selected-start:before {
  @apply rounded-tr-none;
  @apply rounded-br-none;
  @apply right-0;
}
.nice-dates-day.-selected.-selected-end:before {
  @apply rounded-tl-none;
  @apply rounded-bl-none;
  @apply left-0;
}
.nice-dates-day.-selected.-selected-middle:before {
  @apply rounded-none;
  @apply left-0;
  @apply right-0;
}
.nice-dates-day.-selected.-selected-middle:nth-child(7n + 1):before {
  @apply rounded-tl-sm;
  @apply rounded-bl-sm;
}
.nice-dates-day.-selected.-selected-middle:not(.-end):nth-child(7n):before {
  @apply rounded-tr-sm;
  @apply rounded-br-sm;
}
.nice-dates-day.-today {
  @apply font-bold;
}
.nice-dates-day.-wide:before,
.nice-dates-day.-wide:after {
  left: 12.5%;
  right: 12.5%;
}
.nice-dates-day.-outside {
  @apply text-zinc-400/50;
}
.dark .nice-dates-day.-outside {
  @apply text-zinc-600/50;
}

.nice-dates-day.-disabled {
  @apply cursor-default;
  @apply pointer-events-none;
  @apply text-zinc-300;
}

.dark .nice-dates-day.-disabled {
  @apply text-zinc-800;
}

.nice-dates-popover {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
  margin: 8px 0;
  max-width: 600px;
  position: absolute;
  transform-origin: top;
  transform: perspective(1000px);
  transition-property: transform, opacity;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.4, 0.2, 0, 1.1);
  width: 100%;
  will-change: transform, opacity;
  z-index: 9;
}
.nice-dates-popover:not(.-open) {
  opacity: 0;
  pointer-events: none;
  transform: perspective(1000px) rotateX(-15deg);
}
/* } */

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
